$navbar-default-color: #fff;
$navbar-default-bg: #385b39;
$navbar-default-brand-color: #fff;
$navbar-default-brand-hover-color: #2ecc71;
$navbar-default-link-color: #fff;
$navbar-default-link-hover-color: #2ecc71;
$navbar-default-link-active-color: #2ecc71;

$btn-primary-color: #fff;
$btn-primary-bg: #2ecc71;
$btn-primary-border: #2ecc71;